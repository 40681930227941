/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { useEffect } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'

import DashboardPage from 'containers/DashboardPage/Loadable'
import SubscriptionPage from 'containers/SubscriptionPage/Loadable'
import RecipientPage from 'containers/Recipient/Loadable'
import PaymentPage from 'containers/PaymentPage/Loadable'
import AuthorizationPage from 'containers/AuthorizationPage/Loadable'
import CommentPage from 'containers/CommentPage/Loadable'
import ApprovePage from 'containers/ApprovePage/Loadable'
import RejectPage from 'containers/RejectPage/Loadable'
import TrackPage from 'containers/TrackPage/Loadable'
import AdminPage from 'containers/AdminPage/Loadable'
import PrivateRoute from 'components/PrivateRoute'
import NotFoundPage from 'containers/NotFoundPage/Loadable'
import { PATHS, USER_TOKEN_KEY } from 'config/constants'
import SignPage from 'containers/SignPage'
import loadable from 'utils/loadable'
import TrackSubmissionPage from 'containers/TrackSubmissionPage/Loadable'
import CancelPage from 'containers/CancelPage/Loadable'

import GlobalStyle from '../../global-styles'

export default function App() {

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/" component={DashboardPage} />
        <PrivateRoute
          exact
          path={PATHS.SUBSCRIPTION}
          component={SubscriptionPage}
        />
        <PrivateRoute exact path={PATHS.RECIPIENT} component={RecipientPage} />
        <PrivateRoute
          exact
          path={PATHS.AUTHORATION}
          component={AuthorizationPage}
        />
        <PrivateRoute exact path="/payment" component={PaymentPage} />
        <PrivateRoute
          exact
          path="/one-time-payment"
          component={loadable(() => import('containers/PaymentPage/index_old'))}
        />
        <PrivateRoute
          exact
          path="/payment-callback"
          component={loadable(() => import('containers/PaymentCallbackPage'))}
        />
        <Route
          exact
          path="/comment/:submissionId/:step/:respondentEmail/:recipientEmail/:canEditResponse"
          component={CommentPage}
        />
        <Route
          exact
          path="/comment/:submissionId/:step/:respondentEmail/:recipientEmail/:canEditResponse/:forceRecipientSignin"
          component={CommentPage}
        />
        <Route
          exact
          path="/approve/:submissionId/:step/:respondentEmail/:recipientEmail"
          component={ApprovePage}
        />
        <Route
          exact
          path="/approve/:submissionId/:step/:respondentEmail/:recipientEmail/:forceRecipientSignin"
          component={ApprovePage}
        />
        <Route
          exact
          path="/reject/:submissionId/:step/:respondentEmail/:recipientEmail"
          component={RejectPage}
        />
        <Route
          exact
          path="/reject/:submissionId/:step/:respondentEmail/:recipientEmail/:forceRecipientSignin"
          component={RejectPage}
        />
        <Route exact path="/track" component={TrackPage} />
        <Route exact path="/admin/:userId/:formId" component={AdminPage} />

        <Route
          exact
          path="/sign/:submissionId/:step/:respondentEmail/:recipientEmail/:forceRecipientSignin"
          component={SignPage}
        />

        <Route
          exact
          path="/tracking/:submissionId"
          component={TrackSubmissionPage}
        />

        <Route
          exact
          path="/cancel/:submissionId/:respondentEmail"
          component={CancelPage}
        />

        <PrivateRoute
          path="/claim/:claimCode"
          component={loadable(() => import('containers/ClaimPage'))}
        />

        <PrivateRoute
          exact
          path={PATHS.NEED_MY_ACTION}
          component={loadable(() => import('containers/NeedMyActionPage'))}
        />
        <PrivateRoute
          exact
          path={`${PATHS.NEED_MY_ACTION}/:formId`}
          component={loadable(() =>
            import('containers/NeedMyActionPage/DetailPage'),
          )}
        />
        <PrivateRoute
          exact
          path="/ManageForms"
          component={loadable(() => import('containers/MyFormsPage'))}
        />
        <PrivateRoute
          exact
          path="/Form/Transfering"
          component={loadable(() => import('containers/TransferFormPage'))}
        />
        <PrivateRoute
          exact
          path="/Plan/Transfering"
          component={loadable(() => import('containers/TransferPlanPage'))}
        />
        <PrivateRoute
          exact
          path="/Form/:id"
          component={loadable(() => import('containers/MyFormDetailPage'))}
        />
        <Route
          path={'/auth/google/callback'}
          component={loadable(() =>
            import('containers/CallBackGoogleAuthPage'),
          )}
        />
        <Route component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
    </Router>
  )
}
